import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledNamePageBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [30, 30, 50, 50]
  })
)

export const styledNamePage = css`
  ${styledNamePageBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, 422, 680, 680],
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302],
    marginTop: [8],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['center']
  })
)

export const PHeaderBps = css(
  mq({
    width: [328, 422, 680, 680],
  })
)

export const headerMarginBps = css(
  mq({
    marginLeft: [0, 89, 200, 302],
    marginRight: [0, 89, 200, 302]
  })
)

export const header = css`
  display: flex;
  span {
    ${headerMarginBps};
    font-size: 11.8px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
  }
  p{
    ${PHeaderBps};
    ${headerMarginBps};
  }
`