import React from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledNamePage,
  header,
} from '../components/styles/ModelPage.styles'

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column', 'row']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <span>LOREM IPSUM</span>
            <H4>Lorem Ipsum Indolor</H4>
            <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam lobortis diam vitae ante imperdiet, sit amet scelerisque sem dignissim. Pellentesque eleifend tincidunt feugiat.</Paragraph>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Pagina Modelo' />
      <PageTemplate
        title='Pagina Modelo'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
